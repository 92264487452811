import React from 'react';
import classNames from 'classnames';
import { PiPencil } from 'react-icons/pi';
import { Link } from 'react-router-dom';

import { getFileImg } from '../../../Utils';

import {
  trackPeopleDeletePerson,
  trackPeopleEditPerson,
} from '../../../analytics';

import { Spinner, Checkbox } from '../../../components/general';
import { SideIcon } from '../../../components/icons';

import DemoAddOverlay from './demoAddOverlay';

const FaceCircle = (props) => {
  const {
    event,
    person,
    isBig,
    isShowDetails,
    isSpinner,
    onClick,
    onClickDelete,
    deletingFaces,
    isDemo = false,
    handleDemoPeople = () => {},
    isSelectionMode,
    isSelected,
    onClickCheckbox,
  } = props;

  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};

  const handleOnClick = () => {
    if (isDemo) handleDemoPeople(face.elementId, true);
    else if (onClick) {
      onClick();
      trackPeopleEditPerson();
    }
  };

  const handleOnDelete = (e) => {
    e.stopPropagation();

    if (isDemo) handleDemoPeople(face.elementId, false);
    else onClickDelete(e, face);

    trackPeopleDeletePerson(event);
  };

  const containerClass = classNames({
    'w-[var(--upload-el-width)]': isBig,
    'w-[calc((var(--page-width)_-_(1.5rem_*_2))_/_3)] sm:w-[calc((var(--page-width)_-_(1.5rem_*_6))_/_7)]':
      !isBig,
  });

  const circleClass = 'w-full aspect-square rounded-full';

  const blockClass = classNames({
    'group relative transition-transform duration-300': true,
    'cursor-pointer': !!onClick || isDemo,
    'hover:scale-110': !!onClick && !isDemo,
  });

  const face = person.face;

  const faceImage = isSpinner ? (
    <Spinner customSize={circleClass} color="primary" />
  ) : (
    <img
      src={getFileImg(person.files[0])}
      className={`${circleClass} object-cover`}
      alt={`${face.name} Icon`}
    />
  );

  const allowDelete =
    !!onClickDelete &&
    (!isDemo ||
      (!demoData.isLimited && person.isSelected && !person.isGenerated));

  // details view
  if (isShowDetails)
    return (
      <div className="group w-full relative">
        {allowDelete && (
          <div className="w-full absolute">
            <SideIcon
              icon="delete"
              itemType="person"
              isProcessing={deletingFaces.includes(face.elementId)}
              onClick={handleOnDelete}
            />
          </div>
        )}

        <div
          className="group w-full flex items-center p-3 gap-2 sm:gap-4 rounded-md cursor-pointer hover:bg-grey-100"
          onClick={handleOnClick}
        >
          <img
            src={getFileImg(person.files[0])}
            className="w-24 h-24 rounded-full object-cover"
            alt={`${face.name} Icon`}
          />

          <div>
            <h5 className="flex items-center mb-2 gap-1">
              <div>{face.name}</div>{' '}
              <PiPencil className="cursor-pointer hidden group-hover:block hover:fill-[url('#icon-primary-gradient')]" />
            </h5>

            <div className="break-all">
              <div>
                <b>Email</b>: {face.email ?? '—'}
              </div>
              <div>
                <b>Instagram</b>: {face.instagram ?? '—'}
              </div>
              {event.customFieldLabel && (
                <div>
                  <b>{event.customFieldLabel}</b>: {face.customFieldData ?? '—'}
                </div>
              )}
            </div>

            {person.videoLink && (
              <div className="italic">
                Watch generated video{' '}
                <Link
                  to={person.videoLink}
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                >
                  here
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );

  // circle view
  return (
    <div className={containerClass}>
      <button
        className={`${circleClass} ${blockClass}`}
        onClick={handleOnClick}
      >
        {allowDelete && (
          <div className={`${circleClass} absolute`}>
            <SideIcon
              icon="delete"
              itemType="person"
              isProcessing={deletingFaces?.includes(face.elementId)}
              onClick={handleOnDelete}
              hidePopover={isDemo}
            />
          </div>
        )}

        {isDemo && (
          <DemoAddOverlay
            element={person}
            addClass={`${circleClass} text-[1.25rem]`}
          />
        )}

        {faceImage}
      </button>

      <div className="w-full flex items-center justify-center text-center mt-2 gap-2">
        {isSelectionMode && (
          <Checkbox
            checked={isSelected}
            onChange={() => onClickCheckbox(face.elementId)}
          />
        )}

        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
          {face.name}
        </div>
      </div>
    </div>
  );
};

export default FaceCircle;
