import React, { useState, useEffect, useMemo, useRef } from 'react';
import { UserCircleCheck } from '@phosphor-icons/react';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty, getUrl, getDemoSelected } from '../../../Utils';
import { trackAnalysisFinish } from '../../../analytics';

import { SellingPoint } from '../../../components/custom';
import { Button, Alert } from '../../../components/general';
import {
  PageSpinner,
  PageHeader,
  BackBtn,
} from '../../../components/layout/content';
import { ErrorModal } from '../../../components/modals';

import { URL, DEMO_ID, UG_ID } from '../../../constants';

import { getCreationResults } from '../../../services/api/creations';
import { getTemplateData } from '../../../services/api/template';
import { getClipGroups } from '../../../services/selectGroupsService';

import { useEventStore } from '../../../stores/event';

import DoneBtn from '../components/doneBtn';
import { getReviewText } from '../components/getText';
import PeopleBlockComplete from './components/PeopleBlockComplete';
import PeopleBlockOther from './components/PeopleBlockOther';
import ClipsBlocks from './components/clipsBlocks';
import GenerationBlock from './components/generationBlock';
import HlReelPreviewContainer from './components/hlReelPreviewContainer';
import PersonClipsModal from './components/personClipsModal';

const Review = () => {
  const eventId = useParams()?.eventId;

  const eventIdLocal = useEventStore((state) => state.eventId);
  const setEventId = useEventStore((state) => state.setEventId);
  const setDemoEvent = useEventStore((state) => state.setDemoEvent);
  const currentEvent = useEventStore((state) => state.currentEvent);
  const selectionsLocal = useEventStore((state) => state.clipSelections);

  const hasSelectedPeople = useRef(false);

  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  const [isAnalysing, setIsAnalysing] = useState(false);

  // people
  const [clipGroups, setClipGroups] = useState([]);
  const [generatedPeopleIds, setGeneratedPeopleIds] = useState([]);
  const [openedPersonId, setOpenedPersonId] = useState(null);

  // highlight reel
  const [hlReel, setHlReel] = useState(null);
  const [requiredShots, setRequiredShots] = useState([]);

  // clips
  const [clips, setClips] = useState(null);
  const [deletingClips, setDeletingClips] = useState([]);
  const [clipSelections, setClipSelections] = useState([]);

  // selection
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [selectedPeopleIds, setSelectedPeopleIds] = useState([]);

  // demo data
  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  const navigate = useNavigate();

  // set event id on mount
  useEffect(() => {
    if (isDemo) setDemoEvent(eventId);
    else if (eventIdLocal !== eventId) setEventId(eventId);
  }, [eventId, eventIdLocal, isDemo, setDemoEvent, setEventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (currentEvent.error) {
        setPageError(currentEvent.error);
        setIsLoading(false);
        return;
      }

      await fetchPeopleData();
      await fetchClipsData();
      await fetchHlReelData();

      setIsLoading(false);
    };

    if (currentEvent) onMount();
  }, [currentEvent]);

  // on mount – show UG if required
  useEffect(() => {
    const isShow = sessionStorage.getItem('showSampleUG') === 'true';
    if (isShow) window.userGuiding.previewGuide(UG_ID.SAMPLE_P2);
  }, []);

  // if still analysing – redirect to Analysis page; else – add GA event
  useEffect(() => {
    if (isLoading) return;

    if (isAnalysing) navigate(getUrl(URL.VB_ANALYSIS, eventId));
    else {
      const projectsUpd =
        JSON.parse(localStorage.getItem('analysedProjects')) ?? [];

      // check if eventId is not in localStorage analysedProjects – to avoid duplicate events
      if (!projectsUpd.includes(eventId)) {
        trackAnalysisFinish(currentEvent);

        projectsUpd.push(eventId);
        localStorage.setItem('analysedProjects', JSON.stringify(projectsUpd));
      }
    }
  }, [currentEvent, eventId, isAnalysing, isLoading, navigate]);

  // clip selections – find the matching clips for each person's wildcard spot
  useEffect(() => {
    if (isEmpty(requiredShots) || isEmpty(clipGroups)) return;

    const clipSelectionsTemp = [];

    // for each person group – find the selected clips
    clipGroups.forEach((group) => {
      const groupId = group.face.elementId || null;

      const selectionsObj = {
        faceId: groupId,
        wildcardsFiller: requiredShots.map((shot) => ({
          wildCardShotIndex: shot.shotIndex,
          clipId: null,
        })),
      };

      // find if the person already has selections data or set new object
      const foundSelections =
        selectionsLocal.find((s) => s.faceId === groupId) ?? selectionsObj;

      const checkedClips = [];

      // find matching clips
      foundSelections?.wildcardsFiller.forEach((w) => {
        // if wildcard has NO selected clip –  – find if there's matching one
        if (!w.clipId) {
          const duration = requiredShots.find(
            (shot) => shot.shotIndex === w.wildCardShotIndex,
          )?.duration;

          // find the first clip with highest ranking score that matches the wildcard spot
          const matchingClip = group.clips.find((clip) => {
            return (
              !checkedClips.includes(clip.elementId) &&
              clip.segment.durationMilliseconds / 1000 >= duration
            );
          });

          if (matchingClip) w.clipId = matchingClip.elementId;
        }

        // if wildcard has selected clip – check if it still exists (in case clips were deleted)
        else if (
          w.clipId &&
          !group.clips.find((clip) => w.clipId === clip.elementId)
        )
          w.clipId = null;

        // update array of checked clips ids
        if (w.clipId) checkedClips.push(w.clipId);
      });

      clipSelectionsTemp.push(foundSelections);
    });

    setClipSelections(clipSelectionsTemp);
  }, [clipGroups, requiredShots]);

  // clip groups – get complete people, incomplete people from most filled to least, and generated people
  const { completePeople, incompletePeople, generatedPeople } = useMemo(() => {
    if (isEmpty(clipGroups) || isEmpty(clipSelections))
      return { completePeople: [], incompletePeople: [], generatedPeople: [] };

    let completePeople = [];
    let incompletePeople = [];
    let generatedPeople = [];

    clipGroups?.forEach((group) => {
      if (generatedPeopleIds.includes(group.face.elementId))
        generatedPeople.push(group);
      else {
        group.face.missingClips = [];

        group.selectedClips = clipSelections.find(
          (s) => s.faceId === group.face.elementId,
        )?.wildcardsFiller;

        group.selectedClips?.forEach((clip) => {
          if (!clip.clipId) {
            const missingClip = requiredShots.find(
              (shot) => shot.shotIndex === clip.wildCardShotIndex,
            );
            if (missingClip) group.face.missingClips.push(missingClip);
          }
        });

        if (isEmpty(group.face.missingClips)) completePeople.push(group);
        else incompletePeople.push(group);
      }
    });

    incompletePeople.sort(
      (one, other) =>
        one.face.missingClips.length - other.face.missingClips.length,
    );

    return { completePeople, incompletePeople, generatedPeople };
  }, [clipGroups, clipSelections, generatedPeopleIds, requiredShots]);

  // set selected people IDs (all complete by default)
  useEffect(() => {
    if (!hasSelectedPeople.current && !isEmpty(completePeople)) {
      const temp = [];
      completePeople.map((group) => temp.push(group.face.elementId));
      setSelectedPeopleIds(temp);
      hasSelectedPeople.current = true;
    }
  }, [completePeople]);

  const fetchHlReelData = async () => {
    try {
      const id = isDemo ? demoData.event.eventId : eventId;

      const { videoTemplate, requiredShots } = await getTemplateData(id);

      if (!videoTemplate) setIsAnalysing(true);
      else {
        setHlReel(videoTemplate);
        setRequiredShots(requiredShots);
      }
    } catch (err) {
      console.error(`Error fetching the highlight reel: ${err}`);
      setError({ subheading: 'Error fetching the highlight reel' });
    }
  };

  const fetchPeopleData = async () => {
    try {
      // demo event
      if (isDemo) {
        const selectedPeople = getDemoSelected(demoData.people);
        const generatedPeopleIds = selectedPeople
          .filter((person) => person.isGenerated)
          .map((person) => person.face.elementId);

        setGeneratedPeopleIds(generatedPeopleIds);
      }

      // usual event
      else {
        const generatedPeople = await getCreationResults(eventId);

        setGeneratedPeopleIds(
          generatedPeople.map((person) => person.face.elementId),
        );
      }
    } catch (err) {
      console.error(`Error fetching the people: ${err}`);
      setError({ subheading: 'Error fetching the people' });
    }
  };

  const fetchClipsData = async () => {
    try {
      const id = isDemo ? demoData.event.eventId : eventId;

      const { stage, selects, clipGroups } = await getClipGroups(
        id,
        deletingClips,
        isDemo,
      );

      if (stage !== 'finished') setIsAnalysing(true);
      else {
        setClips(selects);
        setClipGroups(clipGroups);
      }
    } catch (err) {
      console.error(`Error fetching the clips: ${err}`);
      setError({ subheading: 'Error fetching the clips' });
    }
  };

  const onClickCheckbox = (elementId, type) => {
    let temp = [];

    if (!!elementId)
      temp = selectedPeopleIds.includes(elementId)
        ? selectedPeopleIds.filter((id) => id !== elementId)
        : [...selectedPeopleIds, elementId];
    else if (type === 'select')
      completePeople.map((p) => temp.push(p.face.elementId));

    setSelectedPeopleIds(temp);
  };

  // person opened for clip re-selection
  const openedPerson = useMemo(() => {
    const foundPerson = clipGroups?.find(
      (person) => person.face.elementId === openedPersonId,
    );

    return foundPerson ?? null;
  }, [clipGroups, openedPersonId]);

  const { reviewAlert, reviewText, toGenerateText } = getReviewText({
    currentEvent,
    hlReel,
    requiredShots,
    completePeople,
    incompletePeople,
    generatedPeople,
    selectedPeopleIds,
    isAnalysisPage: true,
  });

  const allowGenerate = !reviewAlert;

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={`Review – ${currentEvent?.name}`}
      isPageContainer
    >
      <BackBtn label="Video Builder" />

      <div className="flex flex-col gap-8">
        <PageHeader
          heading="Review"
          icons={
            !isDemo &&
            !isEmpty(completePeople) &&
            !isSelectionMode && (
              <Button
                onClick={() => setIsSelectionMode(true)}
                variant="transparent-black"
                form="square"
                isThin
                popover={{
                  header: 'Custom Video Generation',
                  body: 'Select specific people to generate videos for',
                }}
              >
                <UserCircleCheck size="1.25rem" />
              </Button>
            )
          }
        />

        {reviewAlert && (
          <Alert alertData={{ variant: 'warning', text: reviewAlert }} />
        )}

        {hlReel && !isEmpty(clips) && (
          <React.Fragment>
            {reviewText && <SellingPoint text={reviewText} />}

            <PeopleBlockComplete
              event={currentEvent}
              completePeople={completePeople}
              setOpenedPersonId={setOpenedPersonId}
              isSelectionMode={isSelectionMode}
              selectedPeopleIds={selectedPeopleIds}
              onClickCheckbox={onClickCheckbox}
            />

            {allowGenerate && (
              <GenerationBlock
                currentEvent={currentEvent}
                clipSelections={clipSelections}
                toGenerateText={toGenerateText}
                selectedPeopleIds={selectedPeopleIds}
              />
            )}

            {!isEmpty(completePeople) && <hr />}

            <PeopleBlockOther
              event={currentEvent}
              incompletePeople={incompletePeople}
              generatedPeople={generatedPeople}
              requiredShots={requiredShots}
              setOpenedPersonId={setOpenedPersonId}
              isDemo={isDemo}
            />

            <ClipsBlocks
              clips={clips}
              deletingClips={deletingClips}
              setDeletingClips={setDeletingClips}
              fetchClipsData={fetchClipsData}
            />

            <HlReelPreviewContainer
              hlReel={hlReel}
              requiredShots={requiredShots}
            />

            {!allowGenerate && <DoneBtn isBack />}
          </React.Fragment>
        )}
      </div>

      <PersonClipsModal
        clipGroups={(completePeople || []).concat(incompletePeople || [])}
        openedPerson={openedPerson}
        hlReel={hlReel}
        requiredShots={requiredShots}
        clipSelections={clipSelections}
        setClipSelections={setClipSelections}
        openedPersonId={openedPersonId}
        setOpenedPersonId={setOpenedPersonId}
      />

      <ErrorModal
        show={!!error}
        heading={error?.heading ?? 'Something went wrong'}
        subheading={error?.subheading}
        btn={error?.btn}
        isTryAgainBtn={!error?.btn}
      />
    </PageSpinner>
  );
};

export default Review;
