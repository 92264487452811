import React, { useMemo } from 'react';

import classNames from 'classnames';
import { isEmpty, getThumbnail, getCreationVideoUrl } from '../../../Utils';

import { Logo } from '../../../components/general';
import { PageSpinner } from '../../../components/layout/content';
import ShareFooter from './shareFooter';

import VideoDetails from './videoDetails';

const ShareVideoUI = (props) => {
  const {
    isPreview,
    width,
    isLoading,
    pageError,
    title,
    description,
    event,
    person,
    videoRef,
    setPaymentModalState,
  } = props;

  const { face, creation, videoUrl } = useMemo(() => {
    return {
      face: person?.face ?? {},
      creation: !isEmpty(person?.creations) ? person.creations[0] : {},
      videoUrl: getCreationVideoUrl(event, person),
    };
  }, [event, person]);

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={title}
      description={description}
      noTips
      isFullPage
    >
      <div
        className={classNames('mx-auto', {
          'w-[90%] mt-[5%]': width < 640,
          'w-[70%] mt-7': width >= 640,
        })}
      >
        <div
          className={classNames(
            'flex flex-col py-7 bg-grey-100 text-center rounded-md shadow-grey-1',
            { 'px-5 gap-4': width < 640, 'px-20 gap-8': width >= 640 },
          )}
        >
          <Logo withTagLine />

          <h2>
            <b>{face.name ?? 'Name'}</b> at {event?.name ?? 'Event'}
          </h2>

          <video
            id="video"
            className="w-full aspect-video rounded-md"
            src={videoUrl}
            poster={creation.frameId ? getThumbnail(creation.frameId) : null}
            controls
            controlsList="nodownload"
            ref={videoRef}
          />

          <VideoDetails
            event={event}
            person={person}
            videoUrl={videoUrl}
            setPaymentModalState={setPaymentModalState}
            isPreview={isPreview}
            width={width}
          />
        </div>

        <ShareFooter type="share_creation" />
      </div>
    </PageSpinner>
  );
};

export default ShareVideoUI;
