import React from 'react';

import { isEmpty } from '../../../../Utils';

import { trackAnalysisClickOnPerson } from '../../../../analytics';

import { Button } from '../../../../components/general';
import FaceCircle from '../../components/faceCircle';

const PeopleBlockComplete = (props) => {
  const {
    event,
    completePeople,
    setOpenedPersonId,
    isSelectionMode,
    selectedPeopleIds,
    onClickCheckbox,
  } = props;

  const handleClickPerson = (person) => {
    setOpenedPersonId(person.face.elementId);
    trackAnalysisClickOnPerson(event, 'Complete');
  };

  if (isEmpty(completePeople)) return null;

  return (
    <div className="flex flex-col gap-4">
      {isSelectionMode && (
        <div className="flex gap-2 items-center">
          <div className="font-bold">
            Select people you want to generate videos for
          </div>
          <Button
            onClick={() => onClickCheckbox(null, 'select')}
            variant="link"
            className="text-sm"
          >
            Select All
          </Button>
          |
          <Button
            onClick={() => onClickCheckbox(null, 'deselect')}
            variant="link"
            className="text-sm"
          >
            Deselect All
          </Button>
        </div>
      )}

      <div className="flex flex-row flex-wrap gap-6">
        {completePeople.map((person) => (
          <FaceCircle
            key={person.face.elementId}
            person={person}
            onClick={() => handleClickPerson(person)}
            isSelectionMode={isSelectionMode}
            isSelected={selectedPeopleIds.includes(person.face.elementId)}
            onClickCheckbox={onClickCheckbox}
          />
        ))}
      </div>
    </div>
  );
};

export default PeopleBlockComplete;
