import React from 'react';
import moment from 'moment';

import {
  isEmpty,
  isPlural,
  getPluralWord,
  getPluralPhrase,
} from '../../../Utils';
import { trackAnalysisShowClipsWarning } from '../../../analytics';
import { URL } from '../../../constants';

// calculate total progress based on each clip progress
const getAnalysisProgress = ({ hlReel, clips, allClipsCount }) => {
  let progress = 0;

  if (clips) {
    // + 5 to count for highlight reel
    const length = allClipsCount + 5;

    progress += (5 * (!hlReel?.shots ? 30 : 100)) / length;
    progress += (clips.convertingSelects.length * 30) / length;
    progress += (clips.analysingSelects.length * 50) / length;
    progress += (clips.finishedSelects.length * 100) / length;

    progress = Math.round(progress);
  }

  return progress;
};

const getNumOfPeopleText = ({
  completePeople,
  incompletePeople,
  generatedPeople,
  selectedPeopleIds,
}) => {
  const completeCount = completePeople?.length || 0;
  const allCount =
    completeCount +
    (incompletePeople?.length || 0) +
    (generatedPeople?.length || 0);

  const completeText =
    completeCount === allCount ? 'all' : `${completeCount} of ${allCount}`;

  // to generate text
  let toGenerateCount = 0;

  if (!isEmpty(selectedPeopleIds)) {
    completePeople.forEach((p) => {
      if (selectedPeopleIds.includes(p.face.elementId)) toGenerateCount++;
    });
  }

  const toGenerateText =
    completeCount === toGenerateCount
      ? completeText
      : `${toGenerateCount} of ${allCount}`;

  return { completeCount, completeText, toGenerateText };
};

export const getSelectText = ({
  currentEvent,
  isAssetsUploaded,
  isDisableUpload,
}) => {
  const now = moment().utc();
  const releaseDate = moment(
    currentEvent.attendeeReviewData?.releaseDate ?? '',
  );
  const isAttendeeReview = currentEvent?.attendeeReviewData?.isAttendeeReview;
  const isRemindLater = currentEvent?.attendeeReviewData?.isRemindLater;

  const diffInHours = releaseDate.diff(now, 'hours');

  const subheading = isAssetsUploaded
    ? 'All assets are uploaded'
    : 'Upload your highlight reel template, some clips and tell us the people to identify in your clips';

  // analysis has already started
  if (isDisableUpload)
    return {
      subheading,
      alertText: (
        <>
          Assets can no longer be uploaded since the analysis has already
          started.
        </>
      ),
    };

  // is attendee review some assets are missing
  if (isAttendeeReview && !isAssetsUploaded) {
    // release date passed
    if (now > releaseDate)
      return {
        subheading,
        alertText: (
          <>
            The release date has passed, but some assets are still missing, so
            the analysis couldn’t begin.
          </>
        ),
      };
    // there's less than a day to release date
    else if (diffInHours <= 24)
      return {
        subheading,
        alertText: (
          <>
            Analysis starts in{' '}
            <b>
              {diffInHours === 0
                ? 'less than an hour'
                : getPluralPhrase(diffInHours, 'hour')}
            </b>
            , while some assets are still missing.
          </>
        ),
      };
  }

  return {
    subheading,
    alertText: null,
    isAllowRemindLater:
      isAttendeeReview &&
      !isRemindLater &&
      !isAssetsUploaded &&
      diffInHours > 49,
  };
};

export const getAnalyseText = ({ hlReel, clips, peopleCount }) => {
  if (isEmpty(clips)) return { tipText: null, progress: 0 };

  const allClipsCount =
    clips.convertingSelects.length +
    clips.analysingSelects.length +
    clips.finishedSelects.length;

  const progress = getAnalysisProgress({ hlReel, clips, allClipsCount });

  // if assets are still processing
  if (progress < 100) {
    return {
      analyseText: (
        <>
          Our AI is analysing a highlight reel and looking for{' '}
          <b>{getPluralPhrase(peopleCount, 'person')}</b> from your{' '}
          <b>{getPluralPhrase(allClipsCount, 'clip')}</b>.
        </>
      ),
      progress,
    };
  } else
    return {
      analyseText: (
        <>
          Analysis of <b>{getPluralPhrase(allClipsCount, 'clip')}</b> for{' '}
          <b>{getPluralPhrase(peopleCount, 'person')}</b> done.
          {!isEmpty(clips.newSelects) && (
            <>
              <br />
              Pending analysis of{' '}
              <b>{getPluralPhrase(clips.newSelects, 'clip', 'new')}</b>.
            </>
          )}
        </>
      ),
    };
};

export const getReviewText = ({
  currentEvent,
  hlReel,
  requiredShots,
  completePeople,
  incompletePeople,
  generatedPeople,
  selectedPeopleIds,
  isAnalysisPage,
  isGenerationStarted,
}) => {
  const eventId = currentEvent?.eventId;

  const allPeopleCount =
    completePeople.length + incompletePeople.length + generatedPeople.length;

  // review is complete
  if (isGenerationStarted)
    return {
      reviewText: (
        <>
          Review of <b>{getPluralPhrase(allPeopleCount, 'person')}</b> done.
        </>
      ),
    };

  // if highlight reel is processed and is invalid (brand events)
  if (hlReel?.status === 'invalid')
    return {
      reviewAlert: (
        <>
          <b>Your highlight reel has some missing wildcards.</b>
          {!isAnalysisPage ? (
            <>
              <br />
              Review the results for more details.
            </>
          ) : (
            <>
              {' '}
              Re-upload the highlight reel{' '}
              <a href={`/${eventId}${URL.VB_HL_REEL}`}>here</a> to be able to
              proceed.
            </>
          )}
        </>
      ),
    };

  const { completeCount, completeText, toGenerateText } = getNumOfPeopleText({
    completePeople,
    incompletePeople,
    generatedPeople,
    selectedPeopleIds,
  });

  // if there's NOT enough clips for a person
  if (completeCount === 0) {
    // add GA event
    const projectsUpd =
      JSON.parse(localStorage.getItem('clipsWarningProjects')) ?? [];

    // check if eventId is not in localStorage clipsWarningProjects – to avoid duplicate events
    if (!projectsUpd.includes(eventId)) {
      trackAnalysisShowClipsWarning(currentEvent);

      projectsUpd.push(eventId);
      localStorage.setItem('clipsWarningProjects', JSON.stringify(projectsUpd));
    }

    return {
      reviewAlert: (
        <>
          <b>Not enough clips</b>. Minimum of{' '}
          {getPluralPhrase(requiredShots, 'clip', 'matching')}{' '}
          {isPlural(requiredShots) ? 'are' : 'is'} required to create a
          personalised highlight reel.
          {!isAnalysisPage && (
            <>
              <br />
              Review the results for more details.
            </>
          )}
        </>
      ),
    };
  }

  // if there's enough clips for at least one person
  return {
    reviewText: (
      <>
        Our AI found enough clips to generate personalised highlight reels for{' '}
        <b>{completeText} checked-in people</b>.
        {!isAnalysisPage && (
          <>
            <br />
            Review the results to continue.
          </>
        )}
      </>
    ),
    toGenerateText,
  };
};

export const getGenerateText = ({
  completePeople,
  incompletePeople,
  generatedPeople,
  selectionsLocal,
}) => {
  const selectedPeopleCount =
    (!isEmpty(selectionsLocal) ? selectionsLocal : completePeople).length || 0;
  const successfulPeople = (generatedPeople.length || 0) + selectedPeopleCount;
  const allPeopleCount =
    (completePeople.length || 0) +
    (incompletePeople.length || 0) +
    (generatedPeople.length || 0);

  const { toGenerateText } = getNumOfPeopleText({
    completePeople,
    incompletePeople,
    generatedPeople,
    selectedPeopleIds: selectionsLocal.map((p) => p.faceId),
  });

  const progress =
    Math.round((generatedPeople.length / successfulPeople.length) * 100) || 0;

  if (progress < 100)
    return {
      generateText: (
        <>
          Generating Highlight Reel{isPlural(successfulPeople)} for{' '}
          <b>
            {toGenerateText} {getPluralWord(allPeopleCount, 'person')}
          </b>
          .
        </>
      ),
      progress,
    };

  return {
    generateText: (
      <>
        Generation of{' '}
        <b>{getPluralPhrase(successfulPeople, 'Highlight Reel')}</b> done.
      </>
    ),
    progress: 100,
  };
};

export const getShareText = ({ peopleCount }) => {
  if (peopleCount === 0)
    return { shareText: <>Share out Personalised Highlight Reels</> };

  if (peopleCount === 1)
    return {
      shareText: (
        <>Personalised Highlight Reel is ready to share with 1 person!</>
      ),
    };

  return {
    shareText: (
      <>
        Personalised Highlight Reels are ready to share with {peopleCount}{' '}
        people!
      </>
    ),
  };
};
