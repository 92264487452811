import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty } from '../../../../Utils';

import {
  trackClickTakeMeBack,
  trackGenerationStartSuccess,
  trackGenerationStartError,
} from '../../../../analytics';

import { FancyBtn } from '../../../../components/custom';

import { URL, DEMO_ID } from '../../../../constants';

import { generateCreations } from '../../../../services/api/creations';

import { useEventStore } from '../../../../stores/event';

import { Event } from '../../../../types';

interface ClipSelection {
  clipId: string;
  wildCardShotIndex: number;
}

interface ClipSelectionGroup {
  faceId: string;
  wildcardsFiller: ClipSelection[];
}

interface Props {
  currentEvent: Event;
  clipSelections: ClipSelectionGroup[];
  toGenerateText: string;
  selectedPeopleIds: string[];
}

const GenerationBlock: React.FC<Props> = (props) => {
  const { currentEvent, clipSelections, toGenerateText, selectedPeopleIds } =
    props;

  const eventId = currentEvent.eventId;

  const storeClipSelections = useEventStore((state) => state.setClipSelections);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // demo data
  const demoData = JSON.parse(localStorage.getItem('demoData') ?? '{}');
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  const navigate = useNavigate();

  const filteredClipSelections = useMemo(() => {
    const filteredClipSelections: ClipSelectionGroup[] = [];

    clipSelections.forEach((group: ClipSelectionGroup) => {
      // if group has all shots add it to return array
      if (
        group.wildcardsFiller.every((clip: ClipSelection) => clip.clipId) &&
        selectedPeopleIds.includes(group.faceId)
      )
        filteredClipSelections.push(group);
    });

    return filteredClipSelections;
  }, [clipSelections, selectedPeopleIds]);

  const startDemoGeneration = () => {
    clipSelections.forEach((group) => {
      const person = demoData.people.find(
        (p: any) => p.face.elementId === group.faceId,
      );

      // if creation is already 'generated' – don't alter it
      if (person.isGenerated) return;

      const creationData = demoData.creations[group.faceId];

      // select creation based on selected clips
      creationData.forEach((creation: any) => {
        let isMatching = true;

        group.wildcardsFiller.forEach((wildcard) => {
          if (
            creation.matchingClips[wildcard.wildCardShotIndex] !==
            wildcard.clipId
          ) {
            isMatching = false;
            return;
          }
        });

        creation.isSelected = isMatching;
      });

      // set person as complete/incomplete depending on whether they have a matching creation
      person.isComplete = !!creationData.find(
        (creation: any) => creation.isSelected,
      );
    });

    demoData.event.stage = 'generation';
    demoData.isVideosGenerated = false;
    localStorage.setItem('demoData', JSON.stringify(demoData));
  };

  const onSuccessfulGenerationStart = () => {
    trackGenerationStartSuccess(currentEvent);

    // remove eventId from generatedProjects (for GA)
    const generatedProjects =
      JSON.parse(localStorage.getItem('generatedProjects') ?? '') ?? [];
    const projectsUpd = generatedProjects.filter(
      (id: string) => id !== eventId,
    );
    localStorage.setItem('generatedProjects', JSON.stringify(projectsUpd));

    navigate(`/${eventId}${URL.VIDEO_BUILDER}`);
  };

  const onClickGenerate = async () => {
    setIsSubmitting(true);

    // demo event
    if (isDemo) {
      startDemoGeneration();

      setTimeout(() => {
        onSuccessfulGenerationStart();

        setIsSubmitting(false);
      }, 500);
    }

    // usual event
    else {
      storeClipSelections(filteredClipSelections);

      const data = {
        eventId: eventId,
        clipSelections: filteredClipSelections,
      };

      await generateCreations(data)
        .then(async () => {
          onSuccessfulGenerationStart();

          await updateCurrentEvent();
        })
        .catch((err) => {
          trackGenerationStartError(err.message);

          toast.error(err.message);
        });

      setIsSubmitting(false);
    }
  };

  const handleTakeMeBack = () => {
    navigate(`/${eventId}${URL.VIDEO_BUILDER}`);

    trackClickTakeMeBack('generation', currentEvent);
  };

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <div className="flex flex-col items-center text-center gap-2">
        <div className="text-lg font-bold">
          Ready to generate personalised highlight reels?
        </div>
        <div className="italic">
          We'll generate reels for {toGenerateText} checked-in people.
        </div>
      </div>

      <FancyBtn
        text="Generate"
        isSubmitting={isSubmitting}
        onClick={onClickGenerate}
        isDisabled={isEmpty(filteredClipSelections)}
      />

      <div
        className="underline cursor-pointer hover:text-primary-900"
        onClick={handleTakeMeBack}
      >
        Not yet, take me back
      </div>
    </div>
  );
};

export default GenerationBlock;
