import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getUrl } from '../../../Utils';
import { SellingPoint } from '../../../components/custom';
import { Button, ProgressBar } from '../../../components/general';
import {
  PageSpinner,
  PageHeader,
  BackBtn,
} from '../../../components/layout/content';
import { ErrorModal } from '../../../components/modals';

import { URL } from '../../../constants';

import { getTemplateData } from '../../../services/api/template';
import { getClipGroups } from '../../../services/selectGroupsService';

import { useEventStore } from '../../../stores/event';

import DoneBtn from '../components/doneBtn';
import { getAnalyseText } from '../components/getText';
import ClipsBlocks from './components/clipsBlocks';
import HlReelPreviewContainer from './components/hlReelPreviewContainer';

const Analysis = () => {
  const eventId = useParams()?.eventId;

  const eventIdLocal = useEventStore((state) => state.eventId);
  const setEventId = useEventStore((state) => state.setEventId);
  const currentEvent = useEventStore((state) => state.currentEvent);

  const [isLoading, setIsLoading] = useState(true);

  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  // people
  const [clipGroups, setClipGroups] = useState([]);

  // highlight reel
  const [isHlReelProcessing, setIsHlReelProcessing] = useState(true);
  const [hlReel, setHlReel] = useState(null);
  const [requiredShots, setRequiredShots] = useState([]);

  // clips
  const [isClipsProcessing, setIsClipsProcessing] = useState(true);
  const [clips, setClips] = useState(null);
  const [deletingClips, setDeletingClips] = useState([]);

  const navigate = useNavigate();

  // set event id on mount
  useEffect(() => {
    if (eventIdLocal !== eventId) setEventId(eventId);
  }, [eventId, eventIdLocal, setEventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (currentEvent.error) {
        setPageError(currentEvent.error);
        setIsLoading(false);
        return;
      }

      await fetchClipsData(true);
      await fetchHlReelData(true);

      setIsLoading(false);
    };

    if (currentEvent) onMount();
  }, [currentEvent]);

  // if highlight reel is processing – re-fetch every 10 seconds
  useEffect(() => {
    if (isHlReelProcessing) {
      const hlReelInterval = setInterval(async () => {
        await fetchHlReelData();
      }, 10000);

      return () => clearInterval(hlReelInterval);
    }
  }, [eventId, isHlReelProcessing]);

  // if clips are processing – re-fetch every 10 seconds
  useEffect(() => {
    if (isClipsProcessing) {
      const clipsInterval = setInterval(async () => {
        await fetchClipsData();
      }, 10000);

      return () => clearInterval(clipsInterval);
    }
  }, [eventId, isClipsProcessing]);

  // when finished analysing – redirect to Review page
  useEffect(() => {
    if (!isClipsProcessing && !isHlReelProcessing)
      navigate(getUrl(URL.VB_REVIEW, eventId));
  }, [eventId, isClipsProcessing, isHlReelProcessing, navigate]);

  const getAssetsError = (type, url) => {
    return {
      heading: `No ${type} found`,
      subheading: `Please return to previous step and upload the ${type}`,
      btn: (
        <Button
          className="w-full capitalize"
          onClick={() => navigate(getUrl(url, eventId))}
        >
          Upload {type}
        </Button>
      ),
    };
  };

  const fetchHlReelData = async (didMount) => {
    try {
      const { hasTemplate, videoTemplate, requiredShots } =
        await getTemplateData(eventId);

      if (hasTemplate) {
        setHlReel(videoTemplate);
        setRequiredShots(requiredShots);

        // if processing – update state (it re-fetches every 10 seconds)
        setIsHlReelProcessing(true);

        // highlight reel has processed data
        if (videoTemplate) {
          const hasAllFrames = videoTemplate.shots.every(
            (shot) => shot.type !== 'shot' || !!shot.frameId,
          );

          // if thumbnails for all shots are generated – the highlight reel is fully processed
          if (hasAllFrames) {
            setIsHlReelProcessing(false);

            // if page didn't just mount – show success toast
            if (!didMount)
              toast.success('The highlight reel is processed!', {
                toastId: 'highlight-reel-processing-success',
              });
          }
        }
      }

      // if no highlight reel – show error
      else setError(getAssetsError('highlight reel', URL.VB_HL_REEL));
    } catch (err) {
      console.error(`Error fetching the highlight reel: ${err}`);
      setError({ subheading: 'Error fetching the highlight reel' });
    }
  };

  const fetchClipsData = async (didMount) => {
    try {
      const { stage, selects, clipGroups } = await getClipGroups(
        eventId,
        deletingClips,
      );

      setClips(selects);
      setClipGroups(clipGroups);

      switch (stage) {
        case 'processing':
          // if processing – update state (it re-fetches every 10 seconds)
          setIsClipsProcessing(true);
          break;

        case 'finished':
          // if all clips are processed – update state
          setIsClipsProcessing(false);

          // if page didn't just mount – show success toast
          if (!didMount)
            toast.success('All clips are processed!', {
              toastId: 'clips-processing-success',
            });
          break;

        // if no clips – show error
        case 'no-selects':
          setError(getAssetsError('clips', URL.VB_CLIPS));
          break;

        // if no people – show error
        case 'no-faces':
          setError(getAssetsError('people', URL.VB_PEOPLE));
          break;

        default:
          break;
      }
    } catch (err) {
      console.error(`Error fetching the clips: ${err}`);
      setError({ subheading: 'Error fetching the clips' });
    }
  };

  const { analyseText, progress } = getAnalyseText({
    hlReel,
    clips,
    peopleCount: clipGroups?.length,
  });

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={`Analysis – ${currentEvent?.name}`}
      isPageContainer
    >
      <BackBtn label="Video Builder" />

      <div className="flex flex-col gap-8">
        <PageHeader heading="Analysing" />

        {!error && (
          <React.Fragment>
            <SellingPoint text={analyseText} />

            <div className="flex flex-col gap-4 text-center">
              <ProgressBar progress={progress} />

              <div className="font-bold uppercase">Analysing</div>
            </div>

            <ClipsBlocks
              clips={clips}
              deletingClips={deletingClips}
              setDeletingClips={setDeletingClips}
              fetchClipsData={fetchClipsData}
              peopleCount={clipGroups?.length}
              isShowSuccessfulClips
            />

            <HlReelPreviewContainer
              hlReel={hlReel}
              requiredShots={requiredShots}
              showVideo
            />

            <DoneBtn
              isBack
              subheading="Don't worry, we'll continue analysing"
            />
          </React.Fragment>
        )}
      </div>

      <ErrorModal
        show={!!error}
        heading={error?.heading ?? 'Something went wrong'}
        subheading={error?.subheading}
        btn={error?.btn}
        isTryAgainBtn={!error?.btn}
      />
    </PageSpinner>
  );
};

export default Analysis;
