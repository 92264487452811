import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { SellingPoint } from '../../../../components/custom';
import { Alert } from '../../../../components/general';

import { URL } from '../../../../constants';

import { useEventStore } from '../../../../stores/event';

import { getReviewText } from '../../components/getText';
import VBBtn from './vbBtn';

const ReviewSubStep = (props) => {
  const {
    hlReel,
    requiredShots,
    completePeople,
    incompletePeople,
    generatedPeople,
    isGenerationStarted,
    isDemo,
  } = props;

  const { eventId } = useParams();

  const currentEvent = useEventStore((state) => state.currentEvent);

  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};

  const { reviewAlert, reviewText } = getReviewText({
    currentEvent,
    hlReel,
    requiredShots,
    completePeople,
    incompletePeople,
    generatedPeople,
    isGenerationStarted,
  });

  if (isGenerationStarted)
    return (
      <React.Fragment>
        <div>
          {reviewText}{' '}
          {(!isDemo || !demoData.isLimited) && (
            <Link to={`/${eventId}${URL.VB_REVIEW}`}>Review again</Link>
          )}
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      {reviewText && <SellingPoint text={reviewText} />}

      {reviewAlert && (
        <Alert alertData={{ variant: 'warning', text: reviewAlert }} />
      )}

      <VBBtn
        type="review"
        heading="Review"
        subheading="Review people's clips found by AI"
        link={`/${eventId}${URL.VB_REVIEW}`}
      />
    </React.Fragment>
  );
};

export default ReviewSubStep;
